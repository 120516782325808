import React, { useEffect, useRef } from "react";

const CustomContextMenu = ({ x, y, onClose }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose(); // Đóng menu khi bấm chuột trái ra ngoài
      }
    };
    const handleScroll = () => {
      onClose(); // Đóng menu khi trang được cuộn
    };

    document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onClose]);
  return (
    <div
      ref={menuRef}
      style={{
        position: "fixed",
        top: y,
        left: x,
      }}
      className="bg-[#212121] border border-gray-600 w-[250px] flex flex-col items-center justify-center text-white transition-all duration-200 px-3 py-6 rounded-md"
    >
      <ul className="flex flex-col items-center justify-center gap-3">
        <li className="text-lg font-semibold text-center text-green-500">
          Đừng ngại ngần hãy liên hệ với tôi
        </li>
        <li>
          <a
            href="https://www.facebook.com/duchaidev/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
        </li>
        <li>
          <a href="tel:0343335657">034.333.5657</a>
        </li>
        <li>
          <a href="mailto:duchaidev@gmail.com">duchaidev@gmail.com</a>
        </li>
      </ul>
    </div>
  );
};

export default CustomContextMenu;
