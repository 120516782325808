import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import SideBar from "./SideBar";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { particlesOptions, particlesOptionsMobile } from "../particlesOptions";

const Home = lazy(() => import("../component/Home"));
const About = lazy(() => import("../component/About"));
const Project = lazy(() => import("../component/Project"));
const Contact = lazy(() => import("../component/Contact"));
const Main = ({ dataInfo }) => {
  const [activeSection, setActiveSection] = useState("home");
  const windowWidth = window.innerWidth;
  const currentOptions =
    windowWidth < 768 ? particlesOptionsMobile : particlesOptions;
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section[id]");
      const scrollPosition = window.scrollY + 100;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveSection(section.getAttribute("id"));
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex overflow-hidden">
      {/* <CustomContextMenu /> */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        className="absolute w-full h-full"
        options={currentOptions}
      />
      <div className="z-10">
        <SideBar activeSection={activeSection}></SideBar>
      </div>
      <div className="bg-[#181818] w-full z-1 text-white xs:pl-[80px]">
        <Suspense
          fallback={
            <div className="bg-[#181818] min-w-[100vw] min-h-[100vh] flex items-center justify-center">
              <span class="loader"></span>
            </div>
          }
        >
          <Home />
          <About />
          <Project />
          <Contact dataInfo={dataInfo} />
        </Suspense>
      </div>
    </div>
  );
};

export default Main;
