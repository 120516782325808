import React from "react";
import { HashLink } from "react-router-hash-link";

const SideBar = ({ activeSection }) => {
  return (
    <div className="flex xs:gap-10 gap-4 xs:flex-col items-center justify-center h-[80px] min-w-[100vw] xs:min-w-0 xs:max-h-[100vh] xs:w-[80px] bg-[#212121] xs:min-h-[100vh] border-r border-[#4c4b4b] fixed bottom-0 xs:top-0 left-0">
      <HashLink smooth to="#home" className="flex items-center justify-center">
        <img
          loading="lazy"
          src="logo.svg"
          alt="logo"
          className="xs:w-[65%] w-[60px] xs:pb-3 px-2 object-cover xs:border-r-0 border-r border-[#4c4b4b] xs:border-b"
        />
      </HashLink>
      <HashLink smooth to="#home">
        <p
          className={`cursor-pointer p-4 rounded-md ${
            activeSection === "home" ? "bg-[#494a4d]" : "bg-none"
          } transition-all ease-in-out duration-400`}
        >
          <svg
            viewBox="0 0 386 381"
            xmlns="http://www.w3.org/2000/svg"
            className={`${
              activeSection === "home" ? "fill-[#00ff85]" : "fill-[#b9bbbc]"
            }  transition-all ease-in-out duration-400 xs:w-[26px] w-[22px] h-[22px] xs:h-[26px]`}
          >
            <path d="M198.56 2.28011C197.071 0.855916 195.09 0.0610352 193.03 0.0610352C190.97 0.0610352 188.989 0.855916 187.5 2.28011L3.3999 178.15C2.61805 178.898 1.99604 179.797 1.57149 180.792C1.14694 181.787 0.928674 182.858 0.929901 183.94L0.899902 349C0.899902 357.487 4.27132 365.626 10.2725 371.628C16.2736 377.629 24.413 381 32.8999 381H129C133.243 381 137.313 379.314 140.314 376.314C143.314 373.313 145 369.244 145 365V229C145 226.878 145.843 224.844 147.343 223.343C148.843 221.843 150.878 221 153 221H233C235.122 221 237.156 221.843 238.657 223.343C240.157 224.844 241 226.878 241 229V365C241 369.244 242.686 373.313 245.686 376.314C248.687 379.314 252.756 381 257 381H353.06C361.547 381 369.686 377.629 375.687 371.628C381.688 365.626 385.06 357.487 385.06 349V183.94C385.061 182.858 384.843 181.787 384.418 180.792C383.994 179.797 383.372 178.898 382.59 178.15L198.56 2.28011Z"></path>
          </svg>
        </p>
      </HashLink>
      <HashLink smooth to="#about">
        <p
          className={`cursor-pointer p-4 rounded-md ${
            activeSection === "about" ? "bg-[#494a4d]" : "bg-none"
          } transition-all ease-in-out duration-400`}
        >
          <svg
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            className={`${
              activeSection === "about" ? "fill-[#00ff85]" : "fill-[#b9bbbc]"
            }  transition-all ease-in-out duration-400  xs:w-[26px] w-[22px] h-[22px] xs:h-[26px]`}
          >
            <path d="M8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 16V13.2C0 12.6333 0.146 12.1123 0.438 11.637C0.73 11.1617 1.11733 10.7993 1.6 10.55C2.63333 10.0333 3.68333 9.64567 4.75 9.387C5.81667 9.12833 6.9 8.99933 8 9C9.1 9 10.1833 9.12933 11.25 9.388C12.3167 9.64667 13.3667 10.034 14.4 10.55C14.8833 10.8 15.271 11.1627 15.563 11.638C15.855 12.1133 16.0007 12.634 16 13.2V16H0Z" />
          </svg>
        </p>
      </HashLink>
      <HashLink smooth to="#project">
        <p
          className={`cursor-pointer p-4 rounded-md ${
            activeSection === "project" ? "bg-[#494a4d]" : "bg-none"
          } transition-all ease-in-out duration-400`}
        >
          <svg
            viewBox="0 0 20 18"
            xmlns="http://www.w3.org/2000/svg"
            className={`${
              activeSection === "project" ? "fill-[#00ff85]" : "fill-[#b9bbbc]"
            }  transition-all ease-in-out duration-400  xs:w-[26px] w-[22px] h-[22px] xs:h-[26px]`}
          >
            <path d="M18 16V4H2V16H18ZM18 0C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V16C20 16.5304 19.7893 17.0391 19.4142 17.4142C19.0391 17.7893 18.5304 18 18 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H18ZM11 14V12H16V14H11ZM7.58 10L3.57 6H6.4L9.7 9.3C10.09 9.69 10.09 10.33 9.7 10.72L6.42 14H3.59L7.58 10Z" />
          </svg>
        </p>
      </HashLink>
      <HashLink smooth to="#contact">
        <p
          className={`cursor-pointer p-4 rounded-md ${
            activeSection === "contact" ? "bg-[#494a4d]" : "bg-none"
          } transition-all ease-in-out duration-400`}
        >
          <svg
            viewBox="0 0 22 18"
            xmlns="http://www.w3.org/2000/svg"
            className={`${
              activeSection === "contact" ? "fill-[#00ff85]" : "fill-[#b9bbbc]"
            }  transition-all ease-in-out duration-400 xs:w-[26px] w-[22px] h-[22px] xs:h-[26px]`}
          >
            <path d="M21.8337 4.16292V14.4167C21.8337 15.2456 21.517 16.0433 20.9483 16.6465C20.3796 17.2496 19.6019 17.6126 18.7743 17.6613L18.5837 17.6667H3.41699C2.58801 17.6667 1.79035 17.35 1.18721 16.7813C0.584068 16.2126 0.221042 15.4349 0.172409 14.6073L0.166992 14.4167V4.16292L10.3991 10.9847L10.5247 11.0562C10.6728 11.1285 10.8355 11.1661 11.0003 11.1661C11.1652 11.1661 11.3278 11.1285 11.4759 11.0562L11.6016 10.9847L21.8337 4.16292Z" />
            <path d="M18.583 0.333328C19.753 0.333328 20.7789 0.950828 21.3509 1.87925L10.9997 8.78008L0.648438 1.87925C0.920062 1.43808 1.29325 1.06826 1.73687 0.800659C2.18049 0.533056 2.68167 0.375422 3.1986 0.340912L3.41635 0.333328H18.583Z" />
          </svg>
        </p>
      </HashLink>
    </div>
  );
};

export default SideBar;
