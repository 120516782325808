import React, { useEffect, useState } from "react";
import Main from "./layout/Main";
import CustomContextMenu from "./layout/CustomContextMenu";
import axios from "axios";
import { toast } from "react-toastify";

const App = () => {
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuX, setContextMenuX] = useState(0);
  const [contextMenuY, setContextMenuY] = useState(0);
  const [dataInfo, setDataInfo] = useState({}); // Khởi tạo state dataInfo với giá trị ban đầu là một object rỗng {}
  const TELEGRAM_BOT_TOKEN = "7174277728:AAGuXCQEh8G0Zqvd1aDo4RMCbAFJVz1kymA";
  const CHAT_ID = 5760012591;

  const handleContextMenu = (event) => {
    event.preventDefault(); // Ngăn chặn hành động mặc định khi chuột phải
    // Thực hiện hành động tùy chỉnh của bạn ở đây
    setContextMenuVisible(true);
    setContextMenuX(event.clientX);
    setContextMenuY(event.clientY);
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "F12" ||
      (event.key === "F12" && event.keyCode === 123) ||
      // Ngăn chặn việc bấm Ctrl + Shift + I hoặc Fn + Ctrl + Shift + I
      (event.ctrlKey &&
        event.shiftKey &&
        (event.key === "I" || event.keyCode === 73)) ||
      // Ngăn chặn việc bấm Ctrl + U hoặc Fn + Ctrl + U
      (event.ctrlKey && event.key === "U") ||
      // Ngăn chặn việc bấm Ctrl + Shift + C hoặc Fn + Ctrl + Shift + C
      (event.ctrlKey &&
        event.shiftKey &&
        (event.key === "C" || event.keyCode === 67)) ||
      // Ngăn chặn việc bấm Ctrl + Shift + J hoặc Fn + Ctrl + Shift + J
      (event.ctrlKey &&
        event.shiftKey &&
        (event.key === "J" || event.keyCode === 74)) ||
      // Ngăn chặn việc bấm Ctrl + Shift + K hoặc Fn + Ctrl + Shift + K
      (event.ctrlKey &&
        event.shiftKey &&
        (event.key === "K" || event.keyCode === 75)) ||
      // Ngăn chặn việc bấm Ctrl + Shift + C hoặc Fn + Ctrl + Shift + C
      (event.ctrlKey &&
        event.shiftKey &&
        (event.key === "C" || event.keyCode === 67)) ||
      // Ngăn chặn việc bấm Ctrl + Shift + I hoặc Fn + Ctrl + Shift + I
      (event.ctrlKey &&
        event.shiftKey &&
        (event.key === "I" || event.keyCode === 73))
    ) {
      event.preventDefault();
      toast.error("Please contact me. Thank you!");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Lấy IP người dùng
  const fetchUserIP = async () => {
    try {
      const userAgent = navigator.userAgent;
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setDataInfo({
        ip: data.ip,
        userAgent: userAgent,
      });
      await axios.post(
        `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`,
        {
          chat_id: CHAT_ID,
          text: `User IP: ${data.ip}\nUser Agent: ${userAgent}\n`,
        }
      );
    } catch (error) {
      console.error("Error fetching user IP:", error);
    }
  };
  useEffect(() => {
    fetchUserIP();
  }, []);

  return (
    <div
      className="max-w-[100vw] overflow-hidden"
      onContextMenu={handleContextMenu}
    >
      <Main dataInfo={dataInfo}></Main>
      {contextMenuVisible && (
        <CustomContextMenu
          x={contextMenuX}
          y={contextMenuY}
          onClose={() => setContextMenuVisible(false)}
        />
      )}
    </div>
  );
};

export default App;
